import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import TimerText from '../../../atoms/texts/TimerText';
import { Link } from 'react-router-dom';
import DateVertical from 'components/atoms/texts/DateVertical';
import PropTypes from 'prop-types';
import { EventType } from 'types/data/event';
import NowText from 'components/atoms/texts/NowText';
import DiffHoursText from 'components/atoms/texts/DiffHoursText';
import { convertTimezone, getMinutesDiff } from 'utils/date';
import Chips from 'components/molecules/containers/Chips';

const SmallEventCard = ({ event, returnUrl, competences }) => {
    const { id, title, initialDate, finalDate, typeEvent } = event;
    const [minutesToEvent, setMinutesToEvent] = useState(780);

    const initialDateConverted = convertTimezone(initialDate);
    const finalDateConverted = convertTimezone(finalDate);

    const getMinutesToEvent = () => {
        const eventDay = new Date(initialDateConverted);
        const now = new Date();

        return getMinutesDiff(now, eventDay);
    };

    const now = () => {
        const now = new Date();

        return now.getTime() > new Date(initialDateConverted).getTime() && now.getTime() < new Date(finalDateConverted).getTime();
    };

    useEffect(() => {
        setMinutesToEvent(getMinutesToEvent());
        setInterval(() => setMinutesToEvent(getMinutesToEvent()), 10000);
    }, []);

    return (
        <Link to={`/aluno/eventos/${id}${returnUrl ? `?returnUrl=${returnUrl}` : ''}`}>
            <Container>
                <aside>
                    <DateVertical dateString={initialDateConverted.toString()} />
                </aside>
                <main>
                    <header>
                        <h2>
                            {typeEvent}: {title}
                        </h2>
                        {competences && (
                            <Chips
                                data={competences.map((competence) => {
                                    return { description: competence.desription, color: competence.color };
                                })}
                            />
                        )}
                    </header>
                    <footer>
                        {now() ? (
                            <NowText />
                        ) : minutesToEvent < 720 ? (
                            <DiffHoursText minutes={minutesToEvent} />
                        ) : (
                            <TimerText initialDate={initialDateConverted.toString()} finalDate={finalDateConverted.toString()} />
                        )}
                    </footer>
                </main>
            </Container>
        </Link>
    );
};

SmallEventCard.propTypes = {
    event: PropTypes.shape(EventType).isRequired,
    returnUrl: PropTypes.string
};

export default SmallEventCard;
