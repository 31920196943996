import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ContentButton, ContentInput, ContainerDesktop, ContentButtonHistoric } from './styles';
import { Search } from '@mui/icons-material';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { TextField, InputAdornment } from '@mui/material';
import Button from 'components/molecules/buttons/Button';
import HistoricLibraryDrawer from 'components/molecules/drawers/HistoricLibraryDrawer';

const ComponentsHeaderLibrary = ({ value, onChange, footer }) => {
    const [openHistoric, setOpenHistoric] = useState(false);

    return (
        <ContainerDesktop>
            <ContentInput>
                <TextField
                    type="text"
                    value={value}
                    size="small"
                    fullWidth
                    onChange={(e) => onChange(e.target.value)}
                    placeholder="Digite o que está procurando"
                    inputStyle={{ backgroundColor: 'red' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        )
                    }}
                />
            </ContentInput>

            <ContentButton>
                <Button type="button" size="small" width="auto" onClick={() => alert('oi')} color="neutral">
                    Buscar
                </Button>
            </ContentButton>

            <ContentButtonHistoric>
                <Button type="button" size="small" width="auto" onClick={() => setOpenHistoric(!openHistoric)} color="light" align="right">
                    <ArticleOutlinedIcon />
                    Histórico
                </Button>
            </ContentButtonHistoric>

            <HistoricLibraryDrawer title="Histórico" onOpen={openHistoric} onClose={() => setOpenHistoric(false)} />

            {footer && <footer>{footer}</footer>}
        </ContainerDesktop>
    );
};

ComponentsHeaderLibrary.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    footer: PropTypes.string
};

export default ComponentsHeaderLibrary;
